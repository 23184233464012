import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField/TextField'
import Button from '@material-ui/core/Button'
import { Formik } from 'formik'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import ReactCountryFlag from 'react-country-flag'
import countryList from 'react-select-country-list'
import { object, string, bool } from 'yup'
import cn from 'classnames'
import { getProvinces } from '../../lib/getCountryData'
import { Checkbox } from '@material-ui/core'
import { Address } from '../../types'
import MuiPhoneNumber from 'material-ui-phone-number'
type Props = {
  /** callback when the dialog Submit */
  onSubmit: (...args: any[]) => void
  /** initial values of the inputs */
  initState?: Address
  displayBillingCheckbox?: boolean
  onTermsAndConditions: (...args: any[]) => void
  isTermsAndConditions?: boolean
  setTermsAndConditions?: (...args: any[]) => void
  isBillingAddressTheSame?: boolean
  setBillingAddressTheSame?: (...args: any[]) => void
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 25,
    paddingTop: 10,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 20,
    },
  },
  rootStyle: {
    height: 50,
    borderRadius: 10,
    background: '#9a9a9a08',
  },
  labelStyle: {
    top: -1,
    fontFamily: 'Nunito',
    fontSize: 16,
    letterSpacing: '-0.02em',
    color: '#999999',
  },
  checkboxRootStyle: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: -11,
    marginRight: 16,
    marginBottom: 15,
    verticalAlign: 'middle',
  },
  checkboxLabelStyle: {
    top: -1,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    letterSpacing: '0.00938em',
    color: '#999999',
    fontSize: '1rem',
    lineHeight: 1.5,
    fontWeight: 300,
  },
  textField: {
    ...theme.styles.textField,
    width: '100%',
    marginBottom: 15,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 15,
    },
  },
  twoFields: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  secondField: {
    marginLeft: 10,
  },
  input: {
    ...theme.styles.input,
  },
  item: {
    height: 30,
  },
  selectStyle: {
    '&::focus': {
      borderRadius: 10,
    },
  },
  button: {
    ...theme.styles.button,
    height: 50,
    width: '100%',
    ...theme.styles.blueButton,
    border: 'unset',
  },
  termsAndPolicies: {
    textDecoration: 'underline',
    fontWeight: 400,
  },
  noMarginBottom: {
    marginBottom: 0,
  },
}))

export function AddressForm({
  onSubmit,
  initState,
  displayBillingCheckbox = false,
  onTermsAndConditions,
  isTermsAndConditions,
  setTermsAndConditions,
  isBillingAddressTheSame,
  setBillingAddressTheSame,
}: Props) {
  const countries = countryList().getData()
  const classes = useStyles()

  const phoneRegExp = /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/

  const validationSchema = object({
    firstName: string().required('Required'),
    lastName: string().required('Required'),
    email: string()
      .required('Required')
      .email('Must be a valid email'),
    address: string().required('Required'),
    address2: string(),
    province: string(),
    city: string().required('Required'),
    postCode: string().required('Required'),
    country: string().required('Required'),
    phone: string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Required'),
    isBillingAddressTheSame: bool(),
    isTermsAndConditions: displayBillingCheckbox
      ? bool().oneOf([true])
      : bool(),
  })

  return (
    <Formik
      initialValues={{
        firstName: initState.firstName || '',
        lastName: initState.lastName || '',
        email: initState.email || '',
        address: initState.address || '',
        address2: initState.address2 || '',
        province: initState.province || '',
        city: initState.city || '',
        postCode: initState.postCode || '',
        country: initState.country || '',
        phone: initState.phone || '',
        isBillingAddressTheSame: isBillingAddressTheSame || false,
        isTermsAndConditions: isTermsAndConditions || false,
      }}
      onSubmit={values => {
        onSubmit(values)
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
      }: any) => (
        <div className={classes.root}>
          <div className={classes.twoFields}>
            <TextField
              classes={{ root: classes.rootStyle }}
              InputLabelProps={{ classes: { root: classes.labelStyle } }}
              className={classes.textField}
              InputProps={{ className: classes.input }}
              variant="outlined"
              label="First Name"
              name="firstName"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.firstName && !!errors.firstName}
              /*helperText={(touched.firstName && errors.firstName) || ''}*/
              value={values.firstName}
              inputProps={{ 'data-testid': 'firstName' }}
            />

            <TextField
              classes={{ root: classes.rootStyle }}
              InputLabelProps={{ classes: { root: classes.labelStyle } }}
              className={cn(classes.textField, classes.secondField)}
              InputProps={{ className: classes.input }}
              variant="outlined"
              label="Last Name"
              name="lastName"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lastName && !!errors.lastName}
              /*helperText={(touched.lastName && errors.lastName) || ''}*/
              value={values.lastName}
              inputProps={{ 'data-testid': 'lastName' }}
            />
          </div>

          <TextField
            classes={{ root: classes.rootStyle }}
            InputLabelProps={{ classes: { root: classes.labelStyle } }}
            className={classes.textField}
            InputProps={{ className: classes.input }}
            variant="outlined"
            label="Address"
            name="address"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.address && !!errors.address}
            /*helperText={(touched.address && errors.address) || ''}*/
            value={values.address}
            inputProps={{ 'data-testid': 'address' }}
          />

          <TextField
            classes={{ root: classes.rootStyle }}
            InputLabelProps={{ classes: { root: classes.labelStyle } }}
            className={classes.textField}
            InputProps={{ className: classes.input }}
            variant="outlined"
            label="Apartment, suite etc. (Optional)"
            name="address2"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.address2 && !!errors.address2}
            /*helperText={(touched.address2 && errors.address2) || ''}*/
            value={values.address2}
            inputProps={{ 'data-testid': 'address2' }}
          />

          <div className={classes.twoFields}>
            <TextField
              classes={{ root: classes.rootStyle }}
              InputLabelProps={{ classes: { root: classes.labelStyle } }}
              className={classes.textField}
              InputProps={{ className: classes.input }}
              variant="outlined"
              label="Post/Zip Code"
              name="postCode"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.postCode && !!errors.postCode}
              /*helperText={(touched.postCode && errors.postCode) || ''}*/
              value={values.postCode}
              inputProps={{ 'data-testid': 'postCode' }}
            />

            <TextField
              classes={{ root: classes.rootStyle }}
              InputLabelProps={{ classes: { root: classes.labelStyle } }}
              className={cn(classes.textField, classes.secondField)}
              InputProps={{ className: classes.input }}
              variant="outlined"
              label="City"
              name="city"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.city && !!errors.city}
              /*helperText={(touched.city && errors.city) || ''}*/
              value={values.city}
              inputProps={{ 'data-testid': 'city' }}
            />
          </div>

          {!displayBillingCheckbox && (
            <div className={classes.twoFields}>
              <TextField
                classes={{ root: classes.rootStyle }}
                InputLabelProps={{ classes: { root: classes.labelStyle } }}
                className={classes.textField}
                InputProps={{ className: classes.input }}
                variant="outlined"
                label="Country"
                name="country"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.country && !!errors.country}
                value={values.country}
                select
                SelectProps={{ classes: { root: classes.selectStyle } }}
              >
                {countries.map(country => (
                  <MenuItem
                    key={country.value}
                    value={country.value}
                    className={classes.item}
                  >
                    <ReactCountryFlag
                      styleprops={{
                        width: '20px',
                        height: '20px',
                      }}
                      countryCode={country.value}
                      svg
                    />
                    &nbsp; &nbsp;
                    {country.label}
                  </MenuItem>
                ))}
              </TextField>

              {getProvinces(values.country).length > 0 && (
                <TextField
                  classes={{ root: classes.rootStyle }}
                  className={cn(classes.textField, classes.secondField)}
                  variant="outlined"
                  label="Province"
                  name="province"
                  InputProps={{ classes: { root: classes.rootStyle } }}
                  InputLabelProps={{ classes: { root: classes.labelStyle } }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.province}
                  error={touched.province && !!errors.province}
                  select
                  SelectProps={{ classes: { root: classes.selectStyle } }}
                >
                  {getProvinces(values.country).map(province => (
                    <MenuItem
                      key={province}
                      value={province}
                      className={classes.item}
                    >
                      {province}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </div>
          )}

          <MuiPhoneNumber
            InputLabelProps={{ classes: { root: classes.labelStyle } }}
            className={classes.textField}
            InputProps={{ className: classes.input }}
            variant="outlined"
            label="Mobile Phone"
            name="phone"
            value={values.phone}
            onChange={e => setFieldValue('phone', e)}
            onBlur={handleBlur}
            error={touched.phone && !!errors.phone}
            /*helperText={(touched.phone && errors.phone) || ''}*/
            defaultCountry={values?.country?.toLowerCase()}
            inputProps={{ 'data-testid': 'phone' }}
            disableAreaCodes
          />
          <TextField
            classes={{ root: classes.rootStyle }}
            InputLabelProps={{ classes: { root: classes.labelStyle } }}
            className={classes.textField}
            InputProps={{ className: classes.input }}
            variant="outlined"
            label="Email Address"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && !!errors.email}
            /*helperText={(touched.email && errors.email) || ''}*/
            value={values.email}
            type="email"
            inputProps={{ 'data-testid': 'email' }}
          />
          {/*
            <TextField
              classes={{ root: classes.rootStyle }}
              InputLabelProps={{ classes: { root: classes.labelStyle } }}
              className={classes.textField}
              InputProps={{ className: classes.input }}
              variant="outlined"
              label="Country"
              name="country"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.country && errors.country}
              value={values.country}
              select
              >
            {!shippingCountries.length
              ? countries.map(country => (
              <MenuItem key={country.value} value={country.value}>
              <ReactCountryFlag
              styleProps={{
              width: '20px',
              height: '20px',
            }}
              countryCode={country.value}
              svg
              />
              &nbsp; &nbsp;
              {country.label}
              </MenuItem>
              ))
              : shippingCountries.map(countryCode => (
              <MenuItem key={countryCode} value={countryCode}>
              <ReactCountryFlag
              styleProps={{
              width: '20px',
              height: '20px',
            }}
              countryCode={countryCode}
              svg
              />
              &nbsp; &nbsp;
              {getCountryName(countryCode)}
              </MenuItem>
              ))}
              </TextField>
            */}
          {displayBillingCheckbox && (
            <label
              className={cn(classes.checkboxRootStyle, classes.noMarginBottom)}
            >
              <Checkbox
                checked={values.isBillingAddressTheSame}
                name="isBillingAddressTheSame"
                onChange={(...args) => {
                  handleChange(...args)
                  setBillingAddressTheSame(!values.isBillingAddressTheSame)
                }}
                color="primary"
              />
              <span className={classes.checkboxLabelStyle}>
                Billing address is the same
              </span>
            </label>
          )}
          {displayBillingCheckbox && (
            <label className={classes.checkboxRootStyle}>
              <Checkbox
                checked={values.isTermsAndConditions}
                name="isTermsAndConditions"
                onChange={(...args): void => {
                  handleChange(...args)
                  setTermsAndConditions(!values.isTermsAndConditions)
                }}
                color="primary"
                id="acceptTermsAndPolicies"
              />
              <span className={classes.checkboxLabelStyle}>
                Accept{' '}
                <span
                  onClick={(): void => onTermsAndConditions(values)}
                  className={classes.termsAndPolicies}
                >
                  terms and policies
                </span>
              </span>
            </label>
          )}

          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={handleSubmit}
            disabled={!values.isTermsAndConditions && displayBillingCheckbox}
            data-testid="continue-button"
          >
            Continue
          </Button>
        </div>
      )}
    </Formik>
  )
}

export default AddressForm
