/* eslint-disable  @typescript-eslint/camelcase */
import { useMutation } from '@apollo/react-hooks'
import CreatePayment from './graphql/CreatePaymentIntent.graphql'
import ConfirmPayment from './graphql/ConfirmPaymentIntent.graphql'
import { stripeStatus } from '../Constants'

function usePaymentHandler(setOrderId: (args: string) => void) {
  const [createPaymentIntent] = useMutation(CreatePayment)
  const [confirmPaymentIntent] = useMutation(ConfirmPayment)

  const paymentHandler = async (input, stripe) => {
    /** create payment intent */
    const createPaymentResult = await createPaymentIntent({
      variables: { input },
    })

    const createPaymentData = createPaymentResult?.data?.createPayment

    /** handle error on creation */
    if (createPaymentData?.errors?.length) {
      throw new Error(createPaymentData?.errors[0]?.code)
    }

    /** pass the orderId to the state */
    if (typeof setOrderId === 'function') {
      setOrderId(createPaymentData?.orderId)
    }

    const paymentIntentStatus = createPaymentData?.paymentIntent?.status

    if (paymentIntentStatus === stripeStatus.REQUIRES_ACTION) {
      const clientSecret = createPaymentData?.paymentIntent?.client_secret
      await stripe.handleCardAction(clientSecret)
    }

    const confirmPaymentResult = await confirmPaymentIntent({
      variables: {
        input: {
          paymentIntentId: createPaymentData?.paymentIntent.id,
          orderId: createPaymentData?.orderId,
          productId: input?.orderDetails?.productId,
          variant_id: input?.orderDetails?.variant_id,
        },
      },
    })

    const confirmPaymentData = confirmPaymentResult?.data?.confirmPayment

    /** handle error on confirmation */
    if (confirmPaymentData?.errors?.length) {
      throw new Error(confirmPaymentData?.errors[0]?.code)
    }

    return {
      status: confirmPaymentData?.paymentIntent?.status,
      orderId: createPaymentData?.orderId,
    }
  }

  return [paymentHandler]
}

export default usePaymentHandler
