import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '../Avatar/index'
import LabelledNumber from '../LabelledNumber/index'
import ShopDetails from '../ShopDetails/index'
import cn from 'classnames'

type Props = {
  /** url to the Avatar images  */
  logo: string
  /** products number */
  products?: number
  /** sales number */
  sales?: number
  /** likes number */
  likes?: number
  /** Shop name */
  shopName?: string
  className?: string
}

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'grid',
    width: '100%',

    gridTemplateRows: 'auto',
    gridTemplateColumns: '25% 25% 25% 25%',
    gridTemplateAreas: `
                  "avatar labels labels labels "
                  "details details details details "
                  `,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '25% 25% 25% 25%',
      gridTemplateAreas: `
                  "avatar labels labels labels "
                  "avatar details details details "
                  `,
    },
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    maxWidth: 500,
  },
  labels: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: 250,
    gridArea: 'labels',
    justifySelf: 'start',
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 25,
    },
  },
  avatar: {
    marginLeft: 25,
    gridArea: 'avatar',
  },
  details: {
    marginLeft: 25,
    gridArea: 'details',
  },
}))

function OwnerDetails({
  logo,
  products = 0,
  sales = 0,
  likes = 0,
  shopName = '',
  className = '',
}: Props) {
  const classes = useStyles()
  return (
    <div className={cn(classes.container, className)}>
      <div className={classes.avatar}>
        <Avatar logo={logo} diameter={90} isBackground big />
      </div>
      <div className={classes.labels}>
        <LabelledNumber title="Products" number={products} />
        <LabelledNumber title="Sales" number={sales} />
        <LabelledNumber title="Likes" number={likes} />
      </div>
      <div className={classes.details}>
        <ShopDetails shopName={shopName} />
      </div>
    </div>
  )
}

export default OwnerDetails
