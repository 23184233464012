import { Product } from '../../types'

export const getCountries = (productId: string, products: Product[]) => {
  const product = products.find(product => product.id === productId)
  return product ? product?.shippingCountries : []
}

export const getVariants = (
  productId: string,
  products: Product[],
  shippingCountry,
) => {
  const product = products.find(product => product.id === productId)
  if (!product) return []
  return product.variants.filter(variant =>
    variant.shippingCountries.includes(shippingCountry),
  )
}

export const getProduct = (productId: string, products: Product[]) => {
  const product = products.find(product => product.id === productId)
  return product || null
}
