import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Swiper from 'react-id-swiper'

import 'swiper/css/swiper.css'
import './index.css'

type Props = {
  pictures: string[]
}

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    background: theme.palette.white,
    margin: 'auto',
  },
  image: {
    padding: 10,
    maxWidth: '100%',
    maxHeight: 380,
  },
  swiperContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
    listStyle: 'none',
    padding: 0,
    zIndex: 1,
    background: theme.palette.white,
    position: 'relative',
    paddingBottom: 40,
    // borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
    '&::after': {
      content: `''`,
      display: 'block',
      borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
    },
  },
  swiper: {
    position: 'relative',
    width: '100%',
    height: 380,
    zIndex: 1,
    display: 'flex',
    transitionProperty: 'transform',
    boxSizing: 'content-box',
    //borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
  },
}))

export function ProductSlider({ pictures = [] }: Props) {
  const classes = useStyles()

  const params = {
    pagination:
      pictures.length > 1
        ? {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
            dynamicBullets: true,
          }
        : {},
  }

  return (
    <>
      <Swiper
        wrapperClass={classes.swiper}
        containerClass={classes.swiperContainer}
        {...params}
      >
        {pictures.map(picture => (
          <div key={picture} className={classes.container}>
            <img alt="product" src={picture} className={classes.image} />
          </div>
        ))}
      </Swiper>
      {/*<div key={pictures[0]} className={classes.container}>
        <img src={pictures[0]} className={classes.image} />
      </div>*/}
    </>
  )
}

export default ProductSlider
