import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog/Dialog'
// import Slide from '@material-ui/core/Slide/Slide'
import cn from 'classnames'

type Props = {
  open: boolean
  onClose: () => void
  children: React.ReactElement
  className?: string
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    overflow: 'auto',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: 'none',
    background: '#FFFFFF',
    border: '0.5px solid rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    borderRadius: '15px 15px 0 0',
    margin: 0,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    [theme.breakpoints.up('sm')]: {
      width: '80%',
      margin: 'auto',
      borderRadius: '15px 15px 15px 15px',
      position: 'unset',
    },
    minHeight: 200,
  },
}))

/*const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref}  />
}) as any*/

export function DialogContainer({
  open,
  onClose,
  children,
  className = '',
  ...others
}: Props) {
  const classes = useStyles()
  return (
    <Dialog
      className={classes.root}
      classes={{ paper: cn(classes.paper, className) }}
      //fullScreen
      open={open}
      //TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      {...others}
    >
      {children}
    </Dialog>
  )
}

export default DialogContainer
