import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField/TextField'
import Button from '@material-ui/core/Button/Button'
import { Formik } from 'formik'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import { object, string } from 'yup'
import { Variant } from '../../types'
import Title from '../Title/Title'
import { getTotalPrice } from '../../lib/getTotalPrice'

type Props = {
  variants: Variant[]
  /** callback on continue */
  onContinue: (args: any) => void
  onPrevious: (args: any) => void
  onClose: () => void
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 500,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
    position: 'relative',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 25,
    paddingBottom: 30,
  },
  input: {
    marginBottom: 15,
    marginTop: 15,
    minWidth: 250,
  },
  rootStyle: {
    height: 50,
    borderRadius: 10,
    background: '#9a9a9a08',
  },
  labelStyle: {
    top: -1,
    fontFamily: 'Nunito',
    fontSize: 16,
    letterSpacing: '-0.02em',
    color: '#999999',
  },
  arrowBack: {
    // position: 'absolute',
    // top: 35,
    // left: 25,
  },
  selectStyle: {
    '&::focus': {
      borderRadius: 10,
    },
  },
  button: {
    ...theme.styles.button,
    height: 50,
    marginTop: 5,
    ...theme.styles.blueButton,
  },
  myPrice: {
    fontSize: 14,
    color: theme.palette.blue,
    display: 'inline-block',
    letterSpacing: '-0.2px',
  },
  webShopPrice: {
    fontSize: 14,
    color: theme.palette.gray,
    letterSpacing: '-0.2px',
    display: 'inline-block',
    textDecoration: 'line-through',
    paddingLeft: 4,
  },
  item: {
    whiteSpace: 'pre-wrap',
  },
}))

const getVariantName = variant => {
  return `${variant.option1 || ''} ${variant.option2 || ''} ${variant.option3 ||
    ''}`
}

const validationSchema = object({
  variant: string().required('Required'),
})

function VariantsDialog({
  variants = [],
  onContinue,
  onPrevious,
  onClose,
}: Props) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Title
        title="Make your selection"
        onPrevious={onPrevious}
        onClose={onClose}
      />
      <Formik
        initialValues={{
          variant: '',
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          onContinue(values)
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
        }: any) => (
          <div className={classes.container}>
            <TextField
              id="variant-select"
              classes={{ root: classes.rootStyle }}
              className={classes.input}
              variant="outlined"
              label="Variant"
              name="variant"
              InputProps={{ classes: { root: classes.rootStyle } }}
              InputLabelProps={{ classes: { root: classes.labelStyle } }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.variant || ''}
              error={touched.variant && !!errors.variant}
              select
              SelectProps={{ classes: { root: classes.selectStyle } }}
            >
              {variants.map(variant => (
                <MenuItem
                  key={variant.variantId}
                  value={variant.variantId}
                  classes={{ root: classes.item }}
                >
                  {getVariantName(variant)}
                  <span className={classes.myPrice}>
                    &nbsp;
                    {`($${getTotalPrice(
                      variant.price,
                      variant.discountPercentage,
                    )})`}
                  </span>
                  <span className={classes.webShopPrice}>
                    &nbsp;{`($${variant.price})`}
                  </span>
                </MenuItem>
              ))}
            </TextField>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          </div>
        )}
      </Formik>
    </div>
  )
}

export default VariantsDialog
