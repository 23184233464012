import React from 'react'

export function useHandleOpenDialog(setCurrentProductId) {
  const [openDialog, setOpenDialog] = React.useState(false)

  const handleOpenDialog = (productId: string): void => {
    setCurrentProductId(productId)
    setOpenDialog(true)
  }

  const handleCloseDialog = (): void => {
    setCurrentProductId(null)
    setOpenDialog(false)
  }
  return [openDialog, handleOpenDialog, handleCloseDialog] as Array<any>
}
