import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import ButtonBase from '@material-ui/core/ButtonBase'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

type Props = {
  key?: string | undefined
  image?: string
  price?: string
  url?: string
  date?: string
  orderNumber?: number
  deliveryTime: string
  supportEmail?: string
  shopUrl?: string
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  accordion: {
    marginTop: 0,
    marginBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  accordionSummary: {
    paddingLeft: 0,
    paddingRight: 0,
    '& > div': {
      marginTop: '0!important',
      marginBottom: '0!important',
    },
  },
  productImageContainer: {
    width: 128,
    height: 128,
  },
  productImage: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  priceContainer: {
    paddingTop: 5,
    paddingBottom: 5,
  },
})

const OrderCard = ({
  key,
  image,
  price,
  url,
  date,
  orderNumber,
  deliveryTime,
  supportEmail,
  shopUrl,
}: Props) => {
  const classes = useStyles()

  const [showMessage, setShowMessage] = useState('show more')

  const onAccordionCollapseStateChange = (event, expanded) => {
    if (expanded) setShowMessage('show less')
    else setShowMessage('show more')
  }

  return (
    <div className={classes.root} key={key}>
      <Accordion
        className={classes.accordion}
        onChange={onAccordionCollapseStateChange}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={null}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Grid container spacing={2}>
            <Grid item>
              <ButtonBase className={classes.productImageContainer}>
                <img
                  className={classes.productImage}
                  alt="complex"
                  src={image}
                />
                <Divider orientation="vertical" flexItem />
              </ButtonBase>
            </Grid>
            <Grid item xs container>
              <Grid item xs container spacing={2}>
                <Grid
                  item
                  xs
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Typography gutterBottom>
                    <Link
                      href={url}
                      color="textPrimary"
                      variant="subtitle1"
                      underline="always"
                    >
                      <b>{url}</b>
                    </Link>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {date}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={3}
                container
                direction="column"
                spacing={2}
                className={classes.priceContainer}
              >
                <Grid item xs>
                  <Typography variant="h6" color="primary">
                    {price}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textPrimary">
                    {showMessage}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <Grid container>
            <Grid item xs>
              <Typography variant="subtitle2" color="textPrimary">
                Order#: {orderNumber}
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                Delivery: {deliveryTime}
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                Support: {supportEmail}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle2" color="textPrimary">
                <Link
                  href={`https://www.myshop.io/${shopUrl}/terms_and_conditions`}
                  color="textPrimary"
                  variant="subtitle1"
                  underline="always"
                >
                  Term & condition
                </Link>
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                <Link
                  href={`https://www.myshop.io/${shopUrl}/privacy_policy`}
                  color="textPrimary"
                  variant="subtitle1"
                  underline="always"
                >
                  Privacy policy
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
      </Accordion>
    </div>
  )
}

export default OrderCard
