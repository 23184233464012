import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
}

const defaultProps = {
  number: 0,
  title: '',
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '10px 30px',
    [theme.breakpoints.up('sm')]: {
      margin: '10px 35px',
    },
  },
  number: {
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '23px',
    fontFamily: 'Helvetica',
    color: theme.palette.standard,
  },
  title: {
    fontSize: 12,
    fontFamily: 'Helvetica',
    fontWeight: 300,
    lineHeight: '14px',
    color: theme.palette.standard,
  },
}))

export function LabelledNumber({ number, title }) {
  const classes = useStyles({ number, title })
  return (
    <div className={classes.root}>
      <span className={classes.number}>{number}</span>
      <span className={classes.title}>{title}</span>
    </div>
  )
}

LabelledNumber.propTypes = propTypes
LabelledNumber.defaultProps = defaultProps

export default LabelledNumber
