import React, { useRef, useState } from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button/Button'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import ProductQuantity from '../ProductQuantity/ProductQuantity'
import { LikeHeart, LikeRedHeart } from './icons'
import IconButton from '@material-ui/core/IconButton/IconButton'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core'

type Props = {
  /** product name */
  name: string
  /** the price in MyShop */
  myPrice: number
  /** the price in WebShop */
  webShopPrice: number
  onOpenQuantityDialog: () => void
  quantity: number
  onBuy: () => void
  onLike: () => void
  /** does the user like the product */
  isLiked: boolean
  /** number of likes */
  likes: number
  description: string
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 15px 25px 15px',
    background: theme.palette.white,
  },
  name: {
    fontFamily: 'Helvetica',
    fontWeight: 'bold',
    fontSize: 17,
    lineHeight: '18px',
    marginBottom: 3,
    letterSpacing: '-0.2px',
  },
  myPrice: {
    position: 'relative',
    fontSize: 14,
    color: theme.palette.blue,
    display: 'inline-block',
    letterSpacing: '-0.2px',
  },
  webShopPrice: {
    position: 'relative',
    fontSize: 14,
    color: theme.palette.gray,
    letterSpacing: '-0.2px',
    display: 'inline-block',
    textDecoration: 'line-through',
    paddingLeft: 4,
  },
  currency: {
    position: 'absolute',
    fontSize: 8,
    fontWeight: 700,
    top: 2,
  },
  bold: {
    fontWeight: 800,
    fontSize: 16,
    letterSpacing: '-0.3px',
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  actions: {
    display: 'flex',
    paddingTop: 15,
    zIndex: 10,
  },
  buyButton: {
    ...theme.styles.button,
    height: 45,
    padding: 0,
    width: '-webkit-fill-available',
    ...theme.styles.blueButton,
  },
  qtyButton: {
    width: 50,
    height: 45,
    marginRight: 3,
    border: '1px solid rgba(0, 0, 0, 0.2)',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  titleSection: {},
  likeSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 55,
    position: 'absolute',
    right: 5,
    top: 450,
    zIndex: 10,
  },
  likeText: {
    fontSize: 11,
    fontFamily: 'Helvetica',
    lineHeight: '13px',
    color: '#AAAAAA',
    paddingTop: 0,
  },
  infoSection: {
    width: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  likeIcon: {
    padding: 5,
    margin: 0,
    height: 30,
    width: 30,
  },
  showDescription: {
    paddingLeft: 15,
    fontFamily: 'Helvetica',
    fontSize: 12,
    lineHeight: '17px',
    textDecorationLine: 'underline',
    color: '#AAAAAA',
    cursor: 'pointer',
  },
  rootAccordion: {
    boxShadow: 'unset',
    '&::before': {
      content: 'unset',
    },
  },
  rootAccordionSummary: {
    height: 0,
    minHeight: '0!important',
  },
  rootAccordionDetails: {
    padding: 0,
  },
  description: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    lineHeight: '17px',
    color: 'rgba(0, 0, 0, 0.5)',
  },
}))

const ProductDescription = React.forwardRef(
  (
    { description }: { description: string },
    ref: React.MutableRefObject<null>,
  ) => {
    const classes = useStyles()
    return (
      <Accordion classes={{ root: classes.rootAccordion }}>
        <AccordionSummary
          ref={ref}
          classes={{ root: classes.rootAccordionSummary }}
        ></AccordionSummary>
        <AccordionDetails classes={{ root: classes.rootAccordionDetails }}>
          <Typography className={classes.description}>{description}</Typography>
        </AccordionDetails>
      </Accordion>
    )
  },
)

export function ProductFooter({
  name,
  myPrice,
  webShopPrice,
  onOpenQuantityDialog,
  quantity,
  onBuy,
  isLiked,
  onLike,
  likes,
  description,
}: Props) {
  const classes = useStyles()
  const summaryEl = useRef(null)
  const [showDescription, setShowDescription] = useState(false)

  const toggleShowDescription = () => {
    setShowDescription(!showDescription)
  }
  const handleLike = () => {
    onLike()
  }

  const onDescriptionClick = () => {
    summaryEl.current.click()
    toggleShowDescription()
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.infoSection}>
          <span className={classes.name}>{name}</span>
          <div>
            <span className={cn(classes.myPrice, classes.bold)}>
              ${myPrice?.toFixed(2) || ''}
              &nbsp;
            </span>
            <span className={classes.webShopPrice}>
              ${webShopPrice}
              &nbsp;
            </span>
            {description && (
              <span
                className={classes.showDescription}
                onClick={onDescriptionClick}
              >
                {showDescription ? 'Hide' : 'Show'} description
              </span>
            )}
          </div>
          {description && (
            <ProductDescription description={description} ref={summaryEl} />
          )}
        </div>
        <div className={classes.likeSection}>
          <IconButton
            onClick={handleLike}
            edge="end"
            className={classes.likeIcon}
            color="inherit"
            aria-label="menu"
          >
            {isLiked ? <LikeRedHeart /> : <LikeHeart />}
          </IconButton>
          <span className={classes.likeText}>{likes}</span>
        </div>
      </div>
      <div className={classes.actions}>
        <ProductQuantity
          quantity={quantity}
          onOpenQuantityDialog={onOpenQuantityDialog}
          className={classes.qtyButton}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.buyButton}
          onClick={onBuy}
        >
          <ShoppingCartIcon
            className={cn(classes.leftIcon, classes.iconSmall)}
          />
          Buy Now
        </Button>
      </div>
    </div>
  )
}

export default ProductFooter
