import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '../../Avatar/Avatar'

type Props = {
  /** url to the avatar logo  */
  logo: string
  /** the title to be showing in header */
  title: string
  /** the deliveryTime to be showing in header */
  deliveryTime: string
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.white,
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
  },
  title: {
    fontSize: 14,
    fontWeight: 800,
    fontFamily: 'Helvetica',
    lineHeight: '10px',
    paddingTop: 5,
    margin: 0,
  },
  deliveryTime: {
    fontSize: 13,
    fontStyle: 'italic',
    color: theme.palette.gray,
    fontFamily: 'Helvetica',
    lineHeight: '15px',
  },
  logo: {
    margin: '12px 16px',
  },
}))

export function ProductHeader({ logo, title, deliveryTime }: Props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Avatar logo={logo} diameter={38} className={classes.logo} />
      <div>
        <p className={classes.title}>{title}</p>
        <span className={classes.deliveryTime}>
          Free Shipping: {deliveryTime || '2 - 5'} days avg
        </span>
      </div>
    </div>
  )
}

export default ProductHeader
