import { $$, subtractPercent } from 'moneysafe/ledger'
import { $, multiply } from 'moneysafe'

export const getTotalPriceInCents = (
  price: number,
  discountPercentage = 0,
  quantity = 1,
) => {
  return multiply(
    $(getTotalPrice(price, discountPercentage, quantity)),
    100,
  ).toNumber()
}

export const getTotalPrice = (price, discountPercentage = 0, quantity = 1) => {
  if (!price || price < 0 || discountPercentage < 0 || quantity < 0) return 0

  const variantPriceAfterDiscount = $$(
    $(price),
    subtractPercent(discountPercentage),
  )

  return multiply(variantPriceAfterDiscount, quantity).toNumber()
}
