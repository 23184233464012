import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DialogContainer from '../DialogContainer/DialogContainer'
import TextField from '@material-ui/core/TextField/TextField'
import Button from '@material-ui/core/Button/Button'

type Props = {
  /** product id */
  productId: number
  /** quantity of the product  */
  quantity: number
  /** dispatch function from the Store reducer  */
  dispatch: (...args: any[]) => void
  /** is the dialog open? */
  open: boolean
  /** callback when the dialog close */
  onClose: (...args: any[]) => void
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 500,
    width: '100%',
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: '34px',
    margin: 30,
    marginBottom: 15,
    color: '#000',
  },
  textField: {
    ...theme.styles.textField,
    marginBottom: 15,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 15,
    },
  },
  rootStyle: {
    height: 50,
    borderRadius: 10,
    background: '#9a9a9a08',
  },
  labelStyle: {
    top: -1,
    fontFamily: 'Nunito',
    fontSize: 16,
    letterSpacing: '-0.02em',
    color: '#999999',
  },
  input: {
    ...theme.styles.input,
  },
  button: {
    ...theme.styles.button,
    margin: 15,
    height: 50,
    width: '90%',
    ...theme.styles.blueButton,
  },
}))

export function QuantityDialog({
  quantity,
  open,
  onClose,
  dispatch,
  productId,
}: Props) {
  const classes = useStyles()

  const handleUpdateQuantity = event => {
    const {
      target: { value },
    } = event
    dispatch({
      type: 'SET_QUANTITY',
      payload: { productId, quantity: parseInt(value, 10) },
    })
  }

  return (
    <DialogContainer open={open} onClose={onClose}>
      <div className={classes.root}>
        <span className={classes.title}>Select quantity</span>
        <TextField
          variant="outlined"
          label="Quantity"
          type="number"
          value={quantity || ''}
          onChange={handleUpdateQuantity}
          inputProps={{ min: '0', 'data-testid': 'Quantity' }}
          className={classes.textField}
          classes={{ root: classes.rootStyle }}
          InputLabelProps={{ classes: { root: classes.labelStyle } }}
          InputProps={{ className: classes.input }}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          onClick={onClose}
        >
          OK
        </Button>
      </div>
    </DialogContainer>
  )
}

export default QuantityDialog
