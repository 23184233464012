import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar/AppBar'
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton/IconButton'
import { Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog/Dialog'

type Props = {
  shopName?: string
  shopUrl?: string
}

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    color: theme.palette.standard,
    background: theme.palette.white,
    height: 48,
    minHeight: 'unset',
    [theme.breakpoints.up('sm')]: {
      minHeight: 60,
    },
  },
  logo: {
    width: 135,
    paddingLeft: 18,
    [theme.breakpoints.up('sm')]: {
      width: 180,
    },
  },
  menuButton: {},
  menuIcon: {
    fontSize: '2rem',
    color: '#303466',
  },
  appBar: {
    boxShadow: theme.styles.shadow,
  },
  menu: {
    maxWidth: 500,
    width: '90%',
    minHeight: 300,
    background: theme.palette.white,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.06)',
    borderRadius: 30,
    zIndex: 100,
  },
  menuItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 800,
    fontSize: 25,
    lineHeight: '34px',
    marginTop: 20,
  },
  shop: {
    fontFamily: 'Nunito',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '22px',
    paddingBottom: 20,
    textDecoration: 'none',
    color: '#000',
  },
  link: {
    fontFamily: 'Nunito',
    fontSize: 16,
    lineHeight: '22px',
    color: '#999999',
    paddingBottom: 20,
    textDecoration: 'none',
  },
}))

function Menu(props: {
  open: boolean
  onClose: () => void
  shopName: string
  shopUrl: string
  urlPrefix: string
}) {
  const classes = useStyles()

  return (
    <Dialog
      classes={{ paper: classes.menu }}
      open={props.open}
      keepMounted
      onClose={props.onClose}
    >
      <div className={classes.menuItems}>
        <h3 className={classes.title}>Menu</h3>
        {props.shopName && props.shopUrl && (
          <Link
            onClick={props.onClose}
            to={`${props.urlPrefix}`}
            className={classes.shop}
          >
            {props.shopName}
          </Link>
        )}
        {/*<Link className={classes.link} to={`${props.urlPrefix}/history`}>
          Orders history
        </Link>*/}
        <Link
          className={classes.link}
          to={`${props.urlPrefix}/get_your_own_store`}
        >
          Get your own store
        </Link>
        <Link to={`${props.urlPrefix}/faq`} className={classes.link}>
          FAQ
        </Link>
        <Link
          to={`${props.urlPrefix}/terms_and_conditions`}
          className={classes.link}
        >
          Terms & Conditions
        </Link>
        <Link to={`${props.urlPrefix}/privacy_policy`} className={classes.link}>
          Privacy Policy
        </Link>
      </div>
    </Dialog>
  )
}

function Header({ shopName = null, shopUrl = null }: Props) {
  const classes = useStyles()
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen)
  }

  const urlPrefix = shopUrl ? `/${shopUrl}` : ''

  return (
    <div className={classes.container}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.header}>
          <img
            alt="my-shop-logo"
            className={classes.logo}
            src="/static/images/logo-main.png"
          />
          <IconButton
            onClick={toggleMenu}
            edge="end"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            {menuIsOpen ? (
              <CloseIcon className={classes.menuIcon} />
            ) : (
              <MenuIcon className={classes.menuIcon} />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>

      <Menu
        open={menuIsOpen}
        onClose={toggleMenu}
        shopName={shopName}
        shopUrl={shopUrl}
        urlPrefix={urlPrefix}
      />
    </div>
  )
}

export default Header
