import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const propTypes = {
  shopName: PropTypes.string,
}

const defaultProps = {
  shopName: '',
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    marginTop: 0,
    marginBottom: 15,
  },
  shopName: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.standard,
    margin: 0,
  },
  text: {
    fontSize: 13,
    fontFamily: 'Helvetica',
    lineHeight: '18px',
    color: theme.palette.standard,
    margin: 0,
  },
  href: {
    color: '#306588',
    fontWeight: 600,
  },
}))

export function ShopDetails({ shopName }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <p className={classes.shopName}>{shopName}</p>

      <p className={classes.text}>
        Make your own mobile shop at{' '}
        <a href="http://www.brandbassador.com" className={classes.href}>
          brandbassador.com
        </a>
      </p>
      <span className={classes.text}>
        <span role="img" aria-label="check">
          ✅
        </span>{' '}
        All orders ship directly from brands
      </span>
      <span className={classes.text}>
        <span role="img" aria-label="package">
          📦
        </span>{' '}
        Always <b>FREE</b> shipping
      </span>
      <span className={classes.text}>
        <span role="img" aria-label="package">
          🤑
        </span>{' '}
        Always exclusive MyShop discounts
      </span>
    </div>
  )
}

ShopDetails.propTypes = propTypes
ShopDetails.defaultProps = defaultProps

export default ShopDetails
