import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button/Button'
import cn from 'classnames'
import { SuccessIcon } from './SuccessIcon/SuccessIcon'

type Props = {
  brandName: string
  /** callback when the dialog close */
  onClose: () => void
  orderId?: string
  /** the brand support email */
  support: string
  brandWebsite: string
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: '34px',
    margin: 30,
    marginTop: 30,
    color: '#000',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: 25,
  },
  text: {
    fontSize: 14,
    fontFamily: 'Nunito',
    lineHeight: '19px',
    letterSpacing: '-0.02em',
    color: ' #999999',
    textAlign: 'center',
    maxWidth: 300,
  },
  paddingTop: {
    paddingTop: 15,
  },
  success: {
    width: 50,
    height: 50,
  },
  button: {
    ...theme.styles.button,
    marginTop: 30,
    height: 50,
    width: '100%',
    ...theme.styles.blueButton,
  },
  link: {
    textDecoration: 'none',
  },
}))

export function SuccessDialog({
  brandName,
  onClose,
  orderId,
  support,
  brandWebsite,
}: Props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <span className={classes.title}>Order Success!</span>
      <div className={classes.success}>
        <SuccessIcon />
      </div>

      <div className={classes.container}>
        <span className={classes.text}>
          <b> Order number: </b> {orderId}
        </span>
        <span className={classes.text}>
          <b> Support: </b> {support}
        </span>
        <span className={cn(classes.text, classes.paddingTop)}>
          You will shortly get an order confirmation email form{' '}
          <a href={brandWebsite} className={classes.link}>
            <b> {brandName} </b>
          </a>
          .
        </span>

        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          onClick={onClose}
        >
          Done
        </Button>
      </div>
    </div>
  )
}

export default SuccessDialog
