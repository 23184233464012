import React, { useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import OwnerDetails from '../../components/OwnerDetails/OwnerDetails'
import QuantityDialog from '../../components/QuantityDialog/QuantityDialog'
import { useGetShopProductsByUrl } from '../../hooks/useGetShopByUrl'
import { useHandleOpenDialog } from '../../hooks/useHandlers'
import { useStepper } from '../../hooks/useStepper'
import { Steps } from '../../Constants'
import Loader from '../../components/Loader/Loader'
import ProductsGrid from '../../components/ProductsGrid/ProductsGrid'
import Template from '../Template'
import { Product } from '../../types'
import { useLikeProducts } from '../../hooks/useLikeProducts'
import { AppState, initState, reducer } from './Store'
import { getCountries, getProduct, getVariants } from './helpers'
import CheckoutDialog from '../../components/CheckoutDialog'
import { useOrdersHistory } from '../../hooks/useOrdersHistory'

type Props = {
  match: {
    params: {
      shopUrl: string
    }
  }
}

const useStyles = makeStyles((theme: any) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      background: theme.palette.background.default,
      maxWidth: 800,
      margin: 'auto',
    },
    header: {},
    ownerDetails: {
      marginTop: 70,
      [theme.breakpoints.down('xs')]: {
        marginTop: 50,
      },
    },
    products: {
      columnCount: 2,
      [theme.breakpoints.down('xs')]: {
        columnCount: 1,
      },
      columnGap: '1em',
      width: '100%',
    },
    tabs: {
      width: '100%',
      background: theme.palette.white,
    },
  }
})

export function Shop({
  match: {
    params: { shopUrl },
  },
}: Props) {
  const classes = useStyles()
  // state
  const [state, dispatch] = useReducer(reducer, initState as AppState)
  const [currentProductId, setCurrentProductId] = React.useState(null)
  const [step, goToNextStep, goToPreviousStep, resetStep, jumpTo] = useStepper()

  const [likeProduct, isProductLiked] = useLikeProducts(shopUrl)

  // TODO: use the first destructred element which is the list of cashed orders
  const [, onAddToOrdersHistory] = useOrdersHistory()

  // dialogs control
  const [
    openQuantityDialog,
    handleOpenQuantityDialog,
    handleCloseQuantityDialog,
  ] = useHandleOpenDialog(setCurrentProductId)

  const getQuantity = (productId: string) => {
    if (state.quantities[productId] !== 0 && !state.quantities[productId])
      return 1

    return state.quantities[productId]
  }

  const handleClose = () => {
    resetStep()
    setCurrentProductId(null)
  }
  const handleBuy = (productId, products: Product[]) => () => {
    resetStep()
    setCurrentProductId(productId)

    goToNextStep(productId, products)
  }

  const handleContinueShippingDialog = (productId, products) => values => {
    dispatch({
      type: 'SET_SHIPPING_COUNTRY',
      payload: { country: values.country, province: values.province },
    })

    /** Set the variant to the first one */
    const variants = getVariants(productId, products, values.country)
    const variant = variants?.length ? variants[0] : null

    dispatch({ type: 'SET_VARIANT', payload: { variant } })

    goToNextStep(productId, products, false, values.country)
  }

  const handleContinueProductFeaturesDialog = (
    productId,
    products: Product[],
  ) => values => {
    const product = products.find(product => product.id === productId)

    const variant = product.variants.find(
      variant => variant.variantId === values.variant,
    )
    dispatch({ type: 'SET_VARIANT', payload: { variant } })
    goToNextStep(productId, products)
  }

  const handleContinueShippingAddressDialog = (
    productId,
    products: Product[],
  ) => async shippingDetails => {
    dispatch({
      type: 'SET_SHIPPING_DETAILS',
      payload: { details: shippingDetails },
    })

    // set the billing details to shipping details if isBillingAddressTheSame checkbox is checked
    if (shippingDetails.isBillingAddressTheSame) {
      dispatch({
        type: 'SET_BILLING_DETAILS',
        payload: { details: shippingDetails },
      })
    }

    goToNextStep(productId, products, shippingDetails.isBillingAddressTheSame)
  }

  const handleContinueBillingDetailsDialog = (
    productId,
    products: Product[],
  ) => billingDetails => {
    dispatch({
      type: 'SET_BILLING_DETAILS',
      payload: { details: billingDetails },
    })
    goToNextStep(productId, products)
  }

  const handleSuccess = (productId, products: Product[]) => () => {
    goToNextStep(productId, products)
  }

  const handlePrevious = (productId, products: Product[]) => () => {
    goToPreviousStep(productId, products, state?.shippingDetails?.country)
  }

  const setOrderId = orderId => {
    dispatch({ type: 'SET_ORDER_ID', payload: { orderId } })
  }

  const handleTermsAndConditions = (shippingDetails): void => {
    // Save shipping address
    dispatch({
      type: 'SET_SHIPPING_DETAILS',
      payload: { details: shippingDetails },
    })

    jumpTo(Steps.TERMS_AND_POLICIES)
  }

  const handleTermsAndConditionsPrevious = (): void => {
    jumpTo(Steps.SHIPPING_ADDRESS)
  }

  const handlePaymentDialogPrevious = (isBillingAddressTheSame): void => {
    if (isBillingAddressTheSame) {
      jumpTo(Steps.SHIPPING_ADDRESS)
    } else {
      jumpTo(Steps.BILLING_ADDRESS)
    }
  }

  const handleBuyerAcceptsMarketing = () => (buyerAcceptsMarketing): void => {
    dispatch({
      type: 'SET_BUYER_ACCEPTS_MARKETING',
      payload: { buyerAcceptsMarketing },
    })
  }

  const { loading, error, shop, loadMore } = useGetShopProductsByUrl(
    shopUrl,
    true,
  )

  if (loading) return <Loader />
  if (error || !shop) return <div>Error</div>

  const {
    logo,
    productsCount,
    shopName,
    salesCount,
    likes,
    productConnection,
  } = shop

  const products: Product[] = productConnection.edges.map(edge => edge.node)
  const hasMore = productConnection.pageInfo.hasNextPage

  const {
    shippingDetails,
    billingDetails,
    variant,
    orderId,
    buyerAcceptsMarketing,
  } = state

  return (
    <Template shopUrl={shopUrl}>
      <OwnerDetails
        logo={logo || undefined}
        products={productsCount || 0}
        sales={salesCount || 0}
        likes={likes || 0}
        shopName={shopName || ''}
        className={classes.ownerDetails}
      />
      <ProductsGrid
        products={products}
        getQuantity={getQuantity}
        onOpenQuantityDialog={handleOpenQuantityDialog}
        onBuy={handleBuy}
        loadMore={() => loadMore(products.length)}
        hasMore={hasMore}
        onLike={likeProduct}
        isProductLiked={isProductLiked}
      />
      <QuantityDialog
        open={openQuantityDialog}
        onClose={handleCloseQuantityDialog}
        quantity={getQuantity(currentProductId)}
        productId={currentProductId}
        dispatch={dispatch}
      />
      <CheckoutDialog
        open={step !== Steps.NO_DIALOG}
        onClose={handleClose}
        step={step}
        countries={getCountries(currentProductId, products)}
        variants={getVariants(
          currentProductId,
          products,
          state?.shippingDetails?.country,
        )}
        onPrevious={handlePrevious(currentProductId, products)}
        shippingDetails={shippingDetails}
        billingDetails={billingDetails}
        orderDetails={{
          variant,
          shippingDetails,
          billingDetails,
          buyerAcceptsMarketing,
        }}
        product={getProduct(currentProductId, products)}
        quantity={getQuantity(currentProductId)}
        onContinueShippingDialog={handleContinueShippingDialog(
          currentProductId,
          products,
        )}
        onContinueProductFeaturesDialog={handleContinueProductFeaturesDialog(
          currentProductId,
          products,
        )}
        onContinueShippingAddressDialog={handleContinueShippingAddressDialog(
          currentProductId,
          products,
        )}
        onContinueBillingDetailsDialog={handleContinueBillingDetailsDialog(
          currentProductId,
          products,
        )}
        onSuccess={handleSuccess(currentProductId, products)}
        setOrderId={setOrderId}
        orderId={orderId}
        supportEmail={variant.supportEmail}
        onTermsAndConditions={handleTermsAndConditions}
        onTermsAndConditionsPrevious={() => handleTermsAndConditionsPrevious()}
        onPaymentDialogPrevious={isBillingAddressTheSame =>
          handlePaymentDialogPrevious(isBillingAddressTheSame)
        }
        shopUrl={shopUrl}
        onBuyerAcceptsMarketing={handleBuyerAcceptsMarketing()}
        onAddToOrdersHistory={onAddToOrdersHistory}
      />
    </Template>
  )
}

export default Shop
