import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Template from '../Template'
import OrderCard from '../../components/OrderCard'
import { useOrdersHistory } from '../../hooks/useOrdersHistory'

type Props = {
  match: {
    params: {
      shopUrl: string
    }
  }
}
const useStyles = makeStyles((theme: any) => ({
  container: {
    background: theme.palette.background.default,
    margin: '83px 10px 35px 10px',
    display: 'grid',
    gridGap: 25,
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 800,
    fontSize: 25,
    lineHeight: '34px',
  },
  text: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    color: '#999999',
    letterSpacing: '-0.02em',
  },
  h3: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '19px',
    color: '#999999',
    letterSpacing: '-0.02em',
    fontWeight: 'bold',
  },
  link: {
    color: '#999999',
  },
}))

function History({
  match: {
    params: { shopUrl },
  },
}: Props) {
  const classes = useStyles()
  const [ordersHistory] = useOrdersHistory()

  return (
    <Template shopUrl={shopUrl}>
      <div className={classes.container}>
        {ordersHistory.map(order => (
          <OrderCard
            key={order.orderId}
            image={order.photo}
            price={order.price}
            url={order.website}
            date={order.date}
            orderNumber={order.orderId}
            deliveryTime={order.deliveryTime}
            supportEmail={order.supportEmail}
            shopUrl={shopUrl}
          />
        ))}
      </div>
    </Template>
  )
}

export default History
