import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

const propTypes = {
  /** product quantity */
  quantity: PropTypes.number,
  onOpenQuantityDialog: PropTypes.func,
}

const defaultProps = {
  quantity: 1,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    ...theme.styles.button,
  },
  quantity: {
    fontSize: 14,
    fontFamily: 'Helvetica',
    lineHeight: '16px',
  },
  text: {
    fontSize: 7,
    lineHeight: '8px',
    textTransform: 'uppercase',
    fontFamily: 'Helvetica',
    color: theme.palette.gray,
  },
}))

export function ProductQuantity({ quantity, onOpenQuantityDialog, ...others }) {
  const classes = useStyles()
  const { className } = others
  return (
    <div className={cn(classes.root, className)} onClick={onOpenQuantityDialog}>
      <span className={classes.quantity}>{quantity}</span>
      <span className={classes.text}>qty</span>
    </div>
  )
}

ProductQuantity.propTypes = propTypes
ProductQuantity.defaultProps = defaultProps

export default ProductQuantity
