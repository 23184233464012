import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ProductHeader from './ProductHeader/index'
import ProductSlider from './ProductSlider/ProductSlider'
import { ProductFooter } from './ProductFooter/ProductFooter'
import { Product } from '../../types'

type Props = {
  product: Product
  onOpenQuantityDialog: () => void
  quantity: number
  onBuy: () => void
  onLike?: () => void
  isLiked?: boolean
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: 'calc(100vw - 10px)',
    marginBottom: 15,
    boxShadow: '0px 2px 6px 0 rgba(34, 33, 33, 0.04)',
    maxWidth: 400,
    margin: 5,
    background: theme.palette.white,
    border: '1px solid #EAEAEA',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 380,
      marginBottom: 15,
    },
  },
}))

export function ProductCard({
  product,
  onOpenQuantityDialog,
  quantity,
  onBuy,
  isLiked,
  onLike,
}: Props) {
  const classes = useStyles()
  return (
    <div className={classes.root} id={product.id} data-testid={product.id}>
      <ProductHeader
        logo={product?.brand?.logo}
        title={product?.brand?.name}
        deliveryTime={product?.webshop?.deliveryTime}
      />
      <ProductSlider pictures={product.photos} />
      <ProductFooter
        myPrice={product.myShopPrice}
        webShopPrice={product.webShopPrice}
        name={product.name}
        onOpenQuantityDialog={onOpenQuantityDialog}
        quantity={quantity}
        onBuy={onBuy}
        onLike={onLike}
        isLiked={isLiked}
        likes={product?.likes}
        description={product.description}
      />
    </div>
  )
}

export default ProductCard
