import { gql } from 'apollo-boost'

export const GetShopProductsByUrl = gql`
  query shopByUrl(
    $url: String!
    $countAsView: Boolean
    $limit: Int
    $offset: Int
  ) {
    shopByUrl(url: $url, countAsView: $countAsView) {
      id: userId
      logo: image
      shopName: name
      salesCount: salesCount
      productsCount
      likes
      url
      views
      productConnection(limit: $limit, offset: $offset) {
        edges {
          node {
            id
            name: title
            webShopPrice: priceUSD
            myShopPrice
            likes
            views
            photos
            discountPercentage
            shippingCountries
            description
            variants {
              productId
              variantId
              price: priceUSD
              option1
              option2
              option3
              shippingCountries
              discountPercentage
              supportEmail
              stripeAccount: stripeConnectId
            }
            webshop {
              deliveryTime
              shippingCountries
              privacyPolicyUrl
              termsAndConditionsUrl
            }
            brand {
              brandId
              name
              website
              logo
              myShopPaymentAccount
            }
          }
          cursor
        }
        pageInfo {
          totalCount
          hasNextPage
        }
      }
    }
  }
`

export const GetShopByUrl = gql`
  query shopByUrl($url: String!) {
    shopByUrl(url: $url) {
      id: userId
      logo: image
      shopName: name
      salesCount: salesCount
      productsCount
      likes
      url
    }
  }
`
