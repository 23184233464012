import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { useGetShopByUrl } from '../../hooks/useGetShopByUrl'

type Props = {
  children: React.ReactElement[] | React.ReactElement
  shopUrl?: string
}

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: theme.palette.background.default,
    maxWidth: 800,
    margin: 'auto',
  },
}))

export function Template({ children, shopUrl = null }: Props) {
  const classes = useStyles()

  const { shop } = useGetShopByUrl(shopUrl)

  return (
    <div className={classes.container}>
      <Header shopName={shop?.shopName} shopUrl={shopUrl} />
      {children}
      <Footer shopUrl={shopUrl} />
    </div>
  )
}

export default Template
