/* eslint-disable  @typescript-eslint/camelcase */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button/Button'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import LockIcon from '@material-ui/icons/Lock'

import usePaymentHandler from '../../hooks/usePaymentHandler'
import { getTotalPriceInCents } from '../../lib/getTotalPrice'
import { ClipLoader } from 'react-spinners'
import { Address, Variant, Product } from '../../types'
import { stripeStatus } from '../../Constants'
import { getCountryName } from '../../lib/getCountryData'
import { errorMessageFromCode } from '../../lib/utils'

import moment from 'moment'

type Props = {
  orderDetails: {
    variant: Variant
    shippingDetails: Address
    billingDetails: Address
  }
  product: Product
  quantity: number
  onSuccess?: () => void
  setOrderId: (arg: string) => void
  onAddToOrdersHistory: (...args: any[]) => void
}

const useStyles = makeStyles((theme: any) => ({
  checkout: {
    width: '100%',
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 25,
    paddingBottom: 0,
  },
  card: {
    padding: '5px 10px',
    paddingTop: 15,
    width: '100%',
    border: '0.5px solid rgba(0, 0, 0, 0.2)',
    boxSizing: 'border-box',
    borderRadius: 10,
    height: 50,
    background: '#dfdede38',
    [theme.breakpoints.up('sm')]: {
      border: '1px solid rgba(0, 0, 0, 0.2)',
      paddingTop: 14,
    },
  },
  lock: {
    paddingRight: 10,
    fontSize: 30,
    color: '#FFFFFF',
  },
  error: {
    fontSize: 12,
    fontFamily: 'Nunito',
    letterSpacing: '-0.02em',
    color: '#ff1744',
    fontWeight: 600,
  },
  button: {
    ...theme.styles.button,
    height: 50,
    width: '100%',
    ...theme.styles.blueButton,
  },
}))

const getCreatePaymentIntentInput = (
  product,
  orderDetails,
  quantity,
  paymentMethodId,
) => {
  const {
    variant,
    shippingDetails,
    billingDetails,
    buyerAcceptsMarketing,
  } = orderDetails

  const total = getTotalPriceInCents(
    variant.price,
    variant.discountPercentage,
    quantity,
  )

  return {
    paymentDetails: {
      paymentMethodId: paymentMethodId,
      currency: 'usd',
      amount: total,
    },
    orderDetails: {
      productId: product.id,
      variant_id: variant.variantId,
      quantity: quantity,
      email: billingDetails.email,
      shipping_address: {
        first_name: shippingDetails.firstName,
        last_name: shippingDetails.lastName,
        address1: shippingDetails.address,
        address2: shippingDetails.address2,
        city: shippingDetails.city,
        province: shippingDetails.province,
        country: getCountryName(shippingDetails.country),
        zip: shippingDetails.postCode,
        country_code: shippingDetails.country,
        phone: shippingDetails.phone,
      },
      billing_address: {
        first_name: billingDetails.firstName,
        last_name: billingDetails.lastName,
        address1: billingDetails.address,
        address2: billingDetails.address2,
        city: billingDetails.city,
        province: billingDetails.province,
        country: getCountryName(billingDetails.country),
        zip: billingDetails.postCode,
        country_code: billingDetails.country,
        phone: billingDetails.phone,
      },
      buyer_accepts_marketing: buyerAcceptsMarketing,
    },
  }
}

const Status = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
}

const CARD_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      iconColor: '#3F9AF1',
      fontWeight: '300',
      fontFamily: 'Nunito',
      fontSize: '17px',
      letterSpacing: '-0.02em',
    },
    empty: {
      '::placeholder': {
        color: '#999999',
      },
    },
  },
}

export function CheckoutForm({
  orderDetails,
  product,
  quantity,
  onSuccess,
  setOrderId,
  onAddToOrdersHistory,
}: Props) {
  const classes = useStyles()
  const [status, setStatus] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const [paymentHandler] = usePaymentHandler(setOrderId)

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async event => {
    event.preventDefault()
    try {
      /** create payment method */
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      })

      if (error) {
        throw new Error('PAYMENT_ERROR')
      }

      setStatus(Status.LOADING)

      /** create payment intent */
      const input = getCreatePaymentIntentInput(
        product,
        orderDetails,
        quantity,
        paymentMethod?.id,
      )

      const { status, orderId } = await paymentHandler(input, stripe)

      if (status === stripeStatus.SUCCEEDED) {
        setStatus(Status.SUCCESS)
        onAddToOrdersHistory({
          orderId: orderId,
          date: moment().format('D MMM YYYY / k:mm'),
          price: product.myShopPrice,
          photo: product.photos[0],
          website: product.brand.website,
          supportEmail: product.variants[0]?.supportEmail,
          deliveryTime: product.webshop?.deliveryTime || '2 - 5 days',
        })
        onSuccess()
      } else {
        throw new Error('PAYMENT_NOT_SUCCESSFUL')
      }
    } catch (error) {
      setErrorMessage(errorMessageFromCode(error.message))
      setStatus(Status.ERROR)
    }
  }

  return (
    <div className={classes.checkout}>
      {status !== Status.LOADING && (
        <CardElement options={CARD_OPTIONS} className={classes.card} />
      )}
      <ClipLoader
        size={20}
        color={'#919191'}
        loading={status === Status.LOADING}
      />
      <Button
        className={classes.button}
        onClick={handleSubmit}
        disabled={!stripe || status === Status.LOADING}
      >
        <LockIcon className={classes.lock} />
        Pay
      </Button>

      {status === Status.ERROR && (
        <div className={classes.error}>{errorMessage}</div>
      )}
    </div>
  )
}

export default CheckoutForm
