import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { HashLink } from 'react-router-hash-link'
import { Product } from '../../types'

type Props = {
  products: Product[]
  navigateToProduct: (...arg: any[]) => any
}

const useStyles = makeStyles((theme: any) => ({
  gridView: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(100px, 293px))',
    justifyContent: 'center',
    gridGap: 5,
    padding: 5,
    background: theme.palette.background.default,
  },
  pictureContainer: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.white,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    padding: 5,
  },
  picture: {
    maxWidth: '100%',
    maxHeight: 300,
    verticalAlign: 'top',
    objectFit: 'cover',
    objectPosition: '100% 0',
  },
}))

export function GridView({ products, navigateToProduct }: Props) {
  const classes = useStyles()

  const onScroll = el => {
    el.scrollIntoView()
    window.scrollBy(0, -60)
  }

  return (
    <div className={classes.gridView} aria-label="gridView">
      {products.map(product => (
        <div className={classes.pictureContainer} key={`${product.id}-2`}>
          <HashLink
            to={`#${product.id}`}
            onClick={navigateToProduct}
            scroll={onScroll}
          >
            <img
              alt={product.name || 'product'}
              src={product?.photos[0]}
              className={classes.picture}
            />
          </HashLink>
        </div>
      ))}
    </div>
  )
}
