import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CheckoutForm from '../CheckoutForm/CheckoutForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { getTotalPrice } from '../../lib/getTotalPrice'
import { Address, Product, Variant } from '../../types'
import { PaymentAccountTypes } from '../../Constants'
import Title from '../Title/Title'

type Props = {
  onSuccess?: () => void
  product?: Product
  orderDetails?: {
    variant: Variant
    shippingDetails: Address
    billingDetails: Address
  }
  quantity?: number
  onPrevious?: (args: any) => void
  setOrderId: (arg: string) => void
  onClose: () => void
  onAddToOrdersHistory: (arg: string) => void
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 500,
    position: 'relative',
  },
  title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: '34px',
    margin: 30,
    marginBottom: 15,
    color: '#000',
  },
  total: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    position: 'relative',
    right: 10,
  },
  price: {
    position: 'relative',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    color: '#3F9AF1',
    right: 10,
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 200,
    marginBottom: 15,
  },
  poweredByStripe: {
    width: 100,
    height: 21,
    marginBottom: 15,
  },
  arrowBack: {
    position: 'absolute',
    top: 35,
    left: 25,
  },
  paymentMethods: {
    backgroundImage: 'url("/static/images/cards.svg")',
    height: 25,
    width: 250,
    marginTop: 10,
    backgroundSize: 'cover',
  },
  currency: {
    position: 'absolute',
    fontSize: 8,
    fontWeight: 700,
    top: 2,
    right: -20,
  },
  subTitle: {
    fontFamily: 'Nunito',
    fontSize: 14,
    lineHeight: '150%',
    color: '#999999',
    letterSpacing: '-0.02em',
    marginBottom: 10,
    fontWeight: 300,
    paddingBottom: 10,
  },
  changeBilling: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
})

const getBrandStripeAccount = (
  variant: Variant,
  brandUsesBBInvoices: boolean,
): string | undefined => {
  if (brandUsesBBInvoices) {
    return
  }
  return process.env.NODE_ENV !== 'production'
    ? process.env.REACT_APP_TEST_STRIPE_CONNECTED_ACCOUNT
    : variant?.stripeAccount
}

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Nunito:400,300,700,500',
    },
  ],
}

export function PaymentDialog({
  product,
  orderDetails,
  quantity,
  onSuccess,
  onPrevious,
  setOrderId,
  onClose,
  onAddToOrdersHistory,
}: Props) {
  const classes = useStyles()
  const brandUsesBBInvoices =
    product?.brand?.myShopPaymentAccount === PaymentAccountTypes.INVOICING
  const stripeAccount = getBrandStripeAccount(
    orderDetails.variant,
    brandUsesBBInvoices,
  )

  const stripeKey = process.env.REACT_APP_STRIPE_KEY
  const stripePromise = loadStripe(stripeKey, {
    stripeAccount,
  })

  const total = getTotalPrice(
    orderDetails?.variant?.price,
    orderDetails?.variant?.discountPercentage,
    quantity,
  )

  return (
    <div className={classes.root}>
      <Title title="Card Payment" onClose={onClose} onPrevious={onPrevious} />

      <div className={classes.priceContainer}>
        <span className={classes.total}>Total:&nbsp;</span>
        <span className={classes.price}>
          {total?.toFixed(2) || ''}{' '}
          <span className={classes.currency}>USD</span>
        </span>
      </div>

      <div className={classes.paymentMethods} />

      {(stripeAccount || brandUsesBBInvoices) && (
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <CheckoutForm
            product={product}
            orderDetails={orderDetails}
            quantity={quantity}
            onSuccess={onSuccess}
            setOrderId={setOrderId}
            onAddToOrdersHistory={onAddToOrdersHistory}
          />
        </Elements>
      )}

      <span className={classes.subTitle}>
        Billing Address is same as shipping -{' '}
        <span onClick={onPrevious} className={classes.changeBilling}>
          Change
        </span>
      </span>

      <div>
        <img
          className={classes.poweredByStripe}
          alt="stripe logo"
          src="/static/images/powered_by_stripe.svg"
        />
      </div>
    </div>
  )
}

export default PaymentDialog
