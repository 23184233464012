import useLocalStorage from 'react-use/lib/useLocalStorage'
import { useMutation } from '@apollo/react-hooks'
import LikeProduct from './graphql/LikeProduct.graphql'
import UnlikeProduct from './graphql/UnLikeProduct.graphql'
import { GetShopByUrl } from './graphql/GetShopByUrl.graphql'

export function useLikeProducts(shopUrl: string) {
  const [likedProducts, setLikedProducts] = useLocalStorage(
    `${shopUrl}-liked-products`,
    [],
  )
  const [likeProduct] = useMutation(LikeProduct)
  const [unlikeProduct] = useMutation(UnlikeProduct)

  const onLikeProduct = async (productId: string, likesNumber: number) => {
    if (!productId) return
    if (isProductLiked(productId)) {
      setLikedProducts([...likedProducts.filter(id => id !== productId)])
      await unlikeProduct({
        variables: {
          input: {
            productId,
          },
        },
        refetchQueries: [
          {
            query: GetShopByUrl,
            variables: { url: shopUrl },
          },
        ],
        optimisticResponse: {
          __typename: 'Mutation',
          unlikeProduct: {
            __typename: 'Product',
            id: productId,
            likes: likesNumber - 1,
          },
        },
      })
    } else {
      setLikedProducts([...likedProducts, productId])

      await likeProduct({
        variables: {
          input: {
            productId,
          },
        },
        refetchQueries: [
          {
            query: GetShopByUrl,
            variables: { url: shopUrl },
          },
        ],
        optimisticResponse: {
          __typename: 'Mutation',
          likeProduct: {
            __typename: 'Product',
            id: productId,
            likes: likesNumber + 1,
          },
        },
      })
    }
  }

  const isProductLiked = (productId: string) =>
    likedProducts.includes(productId)

  return [onLikeProduct, isProductLiked] as Array<any>
}
