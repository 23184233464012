/**
 * take an error code and return the message
 * that should be displayed to the user
 * */
export function errorMessageFromCode(code: string): string {
  switch (code) {
    case 'ORDER_CREATION_ERROR':
    case 'ORDER_CONFIRMATION_ERROR':
    case 'PRICE_ERROR':
      return "Error on putting the order on the brand's webshop."
    case 'PAYMENT_ERROR':
      return 'Error on processing the payment.'
    default:
      return 'Something went wrong.'
  }
}
