import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs/Tabs'
import Tab from '@material-ui/core/Tab/Tab'
import ListIcon from '@material-ui/icons/List'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import InfiniteScroll from 'react-infinite-scroller'
import cn from 'classnames'
import { GridView } from './GridView'
import { FeedView } from './FeedView'
import { Product } from '../../types'

type Props = {
  products: Product[]
  getQuantity: (...arg: any[]) => number
  onOpenQuantityDialog: (...arg: any[]) => void
  onBuy: (...arg: any[]) => any
  loadMore: (...arg: any[]) => void
  hasMore: boolean
  onLike: (productId: string) => void
  isProductLiked: (productId: string) => boolean
}

const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: theme.palette.background.default,
    maxWidth: 800,
    margin: 'auto',
    width: '100%',
  },
  tabs: {
    width: '100%',
    background: '#FAFAFA',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderLeft: 'none',
    borderRight: 'none',
  },
  tab: {
    fontSize: 32,
    opacity: 0.75,
  },
  unActive: {
    color: 'rgba(0,0,0,0.24)',
  },
}))

export function ProductsGrid({
  products,
  getQuantity,
  onOpenQuantityDialog,
  onBuy,
  loadMore,
  hasMore,
  isProductLiked,
  onLike,
}: Props) {
  const classes = useStyles()
  const [tab, setTab] = useState(0)

  const handleTabChange = (event, selectedTab) => {
    setTab(selectedTab)
  }

  const navigateToProduct = () => {
    handleTabChange({}, 1)
  }
  return (
    <div className={classes.container}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="tabs"
        className={classes.tabs}
      >
        <Tab
          icon={
            <ViewModuleIcon
              className={cn(classes.tab, tab === 1 ? classes.unActive : '')}
            />
          }
          aria-label="grid"
        />
        <Tab
          icon={
            <ListIcon
              className={cn(classes.tab, tab === 0 ? classes.unActive : '')}
            />
          }
          aria-label="feed"
        />
      </Tabs>

      <InfiniteScroll pageStart={0} loadMore={loadMore} hasMore={hasMore}>
        {tab === 0 && (
          <GridView products={products} navigateToProduct={navigateToProduct} />
        )}
        {tab === 1 && (
          <FeedView
            products={products}
            getQuantity={getQuantity}
            onOpenQuantityDialog={onOpenQuantityDialog}
            onBuy={onBuy}
            onLike={onLike}
            isProductLiked={isProductLiked}
          />
        )}
      </InfiniteScroll>
    </div>
  )
}

export default ProductsGrid
